import { useContext, useEffect } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { LoginParams, UserHookBag } from './types'
import { UserContext } from './context'
import { createClient } from '@services/api'
import { fetchUser, updateUser } from './fetchers'
import { SignupDetails } from '@models/user'
// import ReactGA from 'react-ga4'

interface UserHookOptions {
  redirectOnUnauthorized?: boolean
}

export function useUser(options?: UserHookOptions): UserHookBag {
  const queryClient = useQueryClient()
  const tokenHelpers = useContext(UserContext)

  const user = useQuery(
    ['user', { redirectOnUnauthorized: options?.redirectOnUnauthorized }],
    fetchUser,
    {
      enabled: tokenHelpers.hasToken,
      retry: false,
      refetchOnWindowFocus: false
    }
  )

  if (user.status === 'error') {
    tokenHelpers.removeToken()
    localStorage.removeItem('current-report')
    localStorage.removeItem('default-report')

    void queryClient.removeQueries('user')
  }

  const updateMutation = useMutation(updateUser, {
    onSuccess: async () => await queryClient.invalidateQueries('user')
  })

  useEffect(() => {
    void queryClient.invalidateQueries('user')
  }, [tokenHelpers.hasToken])

  const loginMutation = useMutation(async (variables: LoginParams) => {
    const { data } = await createClient().post('/users/login', variables)

    tokenHelpers.setToken(data.token)
    // ReactGA.initialize('G-NQV9Q9G9BG')
    // ReactGA.set({
    //   user_properties: {
    //     id: data.user_unique_id,
    //     username: variables.email
    //   }
    // })
  })

  const logoutMutation = useMutation(async (): Promise<void> => {
    await createClient().get('/users/logout')

    tokenHelpers.removeToken()
    localStorage.removeItem('current-report')
    localStorage.removeItem('default-report')

    void queryClient.removeQueries('user')
    void user.refetch()
  })

  const registerMutation = useMutation(
    async (variables: Omit<SignupDetails, 'confirmPassword' | 'acceptTOC'>) => {
      const { data } = await createClient().post('/users/register', variables)

      tokenHelpers.setToken(data.token)
    },
    {
      onSuccess: async () => await queryClient.invalidateQueries('user')
    }
  )

  return {
    user,
    isLoggedIn: tokenHelpers.hasToken,
    updateInfo: updateMutation,
    login: loginMutation,
    logout: logoutMutation,
    register: registerMutation
  }
}
