import axios, { AxiosRequestConfig } from 'axios'
import { IncomingMessage } from 'http'
import router from 'next/router'

const baseURL = process.env.NEXT_PUBLIC_API_HOSTNAME
const v1 = axios.create({
  baseURL: `${baseURL}/api/1`,
  withCredentials: true
})

v1.interceptors.request.use(
  async function (config) {
    if (typeof window !== 'undefined') {
      const token = localStorage.getItem('majordomo-token')

      if (token) {
        config.headers.Authorization = token
      }
    }

    return config
  },
  async error => await Promise.reject(error)
)

v1.interceptors.response.use(
  async res => res,
  async error => {
    if (error.response.status === 401) {
      void router.push('/login')
    }

    return await Promise.reject(error)
  }
)

export function createClient(req?: IncomingMessage, redirect?: boolean) {
  const config: AxiosRequestConfig = {
    baseURL: typeof window !== 'undefined' ? '/api' : `${baseURL}/api/2`,
    withCredentials: typeof window !== 'undefined'
  }

  if (req) {
    const cookie = req.headers.cookie

    config.headers ??= {
      cookie
    }
  }

  const instance = axios.create(config)

  instance.interceptors.response.use(
    async res => res,
    async error => {
      if (error.response.status === 401 && redirect) {
        void router.push('/login')
      }

      return await Promise.reject(error)
    }
  )

  return instance
}

export { v1 }
