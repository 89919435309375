import { MutationFunction } from 'react-query'

import { User } from './types'
import { createClient, v1 } from '@services/api'

export async function fetchUser({ queryKey }) {
  const [, params] = queryKey
  const { data } = await createClient(
    undefined,
    params.redirectOnUnauthorized
  ).get('/users/me')

  return {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    name: data.name,
    email: data.email,
    occupation: data.occupation,
    acccountType: data.acccountType,
    image: data.image,
    phoneNumber: data.phoneNumber,
    partner: data.partner,
    affiliation: data.affiliation,
    notifications: data.notifications
  }
}

export const updateUser: MutationFunction<
  void,
  Partial<Omit<User, 'picture' | 'name'>>
> = async variables => {
  await v1.put('/users', variables)
}
