import Link from 'next/link'
import { Facebook, Instagram, X } from 'react-feather'

interface FooterProps {
  hideDisclaimer?: boolean
}

export function Footer({ hideDisclaimer }: FooterProps) {
  return (
    <div className="w-full text-xs text-black-300 print:hidden">
      {!hideDisclaimer && (
        <div className="my-12 px-12">
          <p>
            Disclaimer: Thumbtack is not a home inspection company and is not
            responsible for the contents of your home inspection report. Prices
            listed in your Home Repair Estimate are estimates and actual prices
            will vary depending on many factors. Prioritizations in your Home
            Repair Estimate are based upon either the original inspection report
            results, or Thumbtack’s judgment made without expertise and without
            an opportunity to view the property. Prioritizations are solely an
            opinion that should not be relied upon without consulting a service
            professional with a specialization in the issue at hand. Inspector
            Notes are based solely on a review of your home inspection report
            and should not be relied upon without consulting a service
            professional. Always consult a professional to determine next steps.
            Your use of the Home Repair Estimate constitutes your agreement with
            Thumbtack’s{' '}
            <Link href="https://www.thumbtack.com/terms/">
              <a
                className="text-blue-400 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
            </Link>{' '}
            and{' '}
            <Link href="https://www.thumbtack.com/privacy/">
              <a
                className="text-blue-400 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </Link>
            .
          </p>
        </div>
      )}

      <footer className="bg-gray-100">
        {/* <div className="text-gray-700 md:col-start-2 md:col-end-3">
          <h4 className="text-base mb-2 font-bold text-gray-700">Solutions</h4>
          <ul className="mb-8 tracking-wider sm:space-y-0 space-y-4">
            <li>
              <Link href="/repair-pricing/home-buyers">
                <a className="hover:underline">Home Buyers</a>
              </Link>
            </li>
            <li>
              <Link href="/repair-pricing/home-sellers">
                <a className="hover:underline">Home Sellers</a>
              </Link>
            </li>
            <li>
              <Link href="/repair-pricing/realtors">
                <a className="hover:underline">Real Estate Agents</a>
              </Link>
            </li>
            <li>
              <Link href="/repair-pricing/home-inspectors">
                <a className="hover:underline">Home Inspectors</a>
              </Link>
            </li>
            <li>
              <Link href="/repair-pricing/property-investors">
                <a className="hover:underline">Property Investors</a>
              </Link>
            </li>
          </ul>
          <h4 className="text-base mb-2 font-bold text-gray-700">Features</h4>
          <ul className="tracking-wider sm:space-y-0 space-y-4">
            <li>
              <Link href="/features/domoreport-repair-estimates">
                <a className="hover:underline">
                  Home Repair Estimate
                </a>
              </Link>
            </li>
            <li>
              <Link href="/features/upload-property-documents">
                <a className="hover:underline">Documents</a>
              </Link>
            </li>
            <li>
              <Link href="/features/explore-homeowner-resources">
                <a className="hover:underline">Explore</a>
              </Link>
            </li>
          </ul>
        </div> */}
        {/* <div className="text-gray-700 md:col-start-2 md:col-end-3">
          <h4 className="text-base mb-2 font-bold text-gray-700">Discover</h4>
          <ul className="mb-8 tracking-wider sm:space-y-0 space-y-4">
            <li>
              <Link href="/pricing">
                <a className="hover:underline">Pricing</a>
              </Link>
            </li>
            <li>
              <Link href="/about">
                <a className="hover:underline">About Us</a>
              </Link>
            </li>
            <li>
              <Link href="/blog">
                <a className="hover:underline">Blog</a>
              </Link>
            </li>
            <li>
              <Link href="/home-agent-toolkit">
                <a className="hover:underline">Agent Toolkit</a>
              </Link>
            </li>
            <li>
              <Link href="/newsroom">
                <a className="hover:underline">Newsroom</a>
              </Link>
            </li>
          </ul>
          <h4 className="text-base mb-2 font-bold text-gray-700">My Account</h4>
          <ul className="tracking-wider sm:space-y-0 space-y-4">
            {user ? (
              <li>
                <Link href="/">
                  <a>My Properties</a>
                </Link>
              </li>
            ) : (
              <>
                <li>
                  <Link href="/login">
                    <a>Log In</a>
                  </Link>
                </li>
                <li>
                  <Link href="/signup">
                    <a>Sign Up</a>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div> */}
        <div className="bg-gray-100 grid md:grid-cols-12 grid-cols-1 md:gap-8 gap-4 md:px-16 md:py-16 px-4 py-6 rounded-t-xl md:grid-rows-1">
          <div className="md:col-span-3 flex flex-col items-start justify-center md:justify-start">
            <Link href="/">
              <a>
                <img
                  src="/images/thumbtack/wordmark_dark.png"
                  className="mb-2 w-48"
                  alt="thumbtack logo"
                />
              </a>
            </Link>
            <p className="text-xs">
              © Copyright {new Date().getFullYear()} | All rights reserved
            </p>
          </div>

          <div className="md:col-span-9">
            {/* <h4 className="text-base mb-2 font-bold text-gray-700">Support</h4> */}
            <ul className="sm:mb-8 tracking-wider sm:space-y-4 space-y-0">
              {/* <li>
              <Link href="https://intercom.help/majordomo/en/">
                <a
                  className="hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQ
                </a>
              </Link>
            </li>
            <li>
              <Link href="/contact-us">
                <a className="hover:underline">Contact Us</a>
              </Link>
            </li> */}
              <li>
                <Link href="https://help.thumbtack.com/">
                  <a className="hover:text-blue-400">Help center</a>
                </Link>
              </li>
              <li>
                <Link href="https://www.thumbtack.com/terms/">
                  <a className="hover:text-blue-400">Terms of service</a>
                </Link>
              </li>
              <li>
                <Link href="https://www.thumbtack.com/privacy/">
                  <a
                    className="hover:text-blue-400"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy policy
                  </a>
                </Link>
              </li>
              <li>
                <Link href="https://www.thumbtack.com/privacy/#supplemental-privacy-notice-for-california-residents">
                  <a
                    className="hover:text-blue-400"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CA privacy notice
                  </a>
                </Link>
              </li>
              <li>
                <Link href="https://www.thumbtack.com/syndication-opt-out">
                  <a
                    className="hover:text-blue-400"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Do not sell or share my personal information
                  </a>
                </Link>
              </li>
              <li>
                <div className="flex space-x-2">
                  <Link href="https://www.instagram.com/thumbtack/">
                    <a
                      className="hover:text-blue-400"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Instagram size={18} />
                    </a>
                  </Link>
                  <Link href="https://www.twitter.com/thumbtack">
                    <a
                      className="hover:text-blue-400"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        aria-label="Twitter"
                        height="18"
                        width="18"
                        fill="none"
                        viewBox="0 0 18 18"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.412 7.84l5.526-6.419h-1.31L9.83 6.994 5.998 1.421h-4.42l5.795 8.427-5.794 6.73h1.31l5.066-5.884 4.047 5.885h4.42l-6.01-8.74zM8.62 9.922l-.588-.84L3.36 2.406H5.37l3.77 5.389.587.84 4.9 7.004h-2.01l-4-5.716z"
                          fill="#000"
                        ></path>
                      </svg>
                    </a>
                  </Link>
                  <Link href="https://www.pinterest.com/thumbtack/">
                    <a
                      className="hover:text-blue-400"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        aria-label="Pinterest"
                        height="18"
                        width="18"
                        fill="currentColor"
                        viewBox="0 0 18 18"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9.568 1C5.202 1 3 4.13 3 6.741c0 1.581.598 2.987 1.882 3.51.21.087.399.004.46-.23.042-.16.143-.567.188-.737.061-.23.037-.31-.133-.512-.37-.436-.607-1.001-.607-1.802 0-2.322 1.738-4.402 4.525-4.402 2.468 0 3.824 1.508 3.824 3.522 0 2.65-1.172 4.887-2.913 4.887-.962 0-1.681-.795-1.45-1.77.275-1.165.81-2.42.81-3.262 0-.752-.404-1.38-1.239-1.38-.983 0-1.772 1.017-1.772 2.38 0 .867.293 1.454.293 1.454l-1.182 5.008c-.351 1.486-.053 3.308-.028 3.491.015.11.155.136.219.054.09-.119 1.261-1.564 1.659-3.008.113-.409.646-2.526.646-2.526.32.61 1.253 1.145 2.245 1.145 2.954 0 4.959-2.693 4.959-6.298C15.386 3.54 13.077 1 9.568 1z"></path>
                      </svg>
                    </a>
                  </Link>
                  <Link href="https://www.facebook.com/Thumbtack">
                    <a
                      className="hover:text-blue-400"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Facebook size={18} />
                    </a>
                  </Link>
                </div>
              </li>
            </ul>
            {/* <h4 className="text-base mb-2 font-bold text-gray-700">Connect</h4>
          <ul className="tracking-wider sm:space-y-0 space-y-4">
            <li>
              <Link href="https://www.facebook.com/majordomohq/">
                <a
                  className="hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </Link>
            </li>
            <li>
              <Link href="https://www.linkedin.com/company/majordomohq/">
                <a
                  className="hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </Link>
            </li>
            <li>
              <Link href="https://www.youtube.com/channel/UCAPucIfzWYlAXL4siJKEYWw">
                <a
                  className="hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTube
                </a>
              </Link>
            </li>
          </ul> */}
          </div>
        </div>
      </footer>
    </div>
  )
}
